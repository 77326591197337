<script setup lang="ts">
import { Promotion } from "~/types";

import type { KeenSliderOptions } from "keen-slider";

const { t } = useT();
const loginGuard = useLoginGuard();
const router = useRouter();
const { open } = useAppModals();
const { data: appInit } = useAppInitData();
const { season } = appInit.value || {};
const { homePromotions, openCash } = usePromotionsData();
const { handleClickCopyLink } = useReferralsInfo();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const sliderRef = ref();

const currencySymbol = computed(() => appInit.value?.currencySymbol ?? "$");
const wheelText = computed(() =>
	appInit.value?.isGuest
		? "Create an account and get your prize now!"
		: "Boost your game with free coins - get your prize now!"
);

const sliderOptions: KeenSliderOptions = {
	slides: { perView: "auto" },
	created(slider) {
		setTimeout(() => {
			slider.update();
		}, 1500);
	}
};

const handleNavigateToPromotions = () => {
	loginGuard({
		success: () => {
			router.push("/promotions/");
		},
		fail() {
			open("LazyOModalSignup");
		}
	});
};

const getImgFullPath = (src: string) => {
	const isNuxtImg = src.includes("nuxt-img");
	return isNuxtImg ? src : `${baseImageUrl}${src}`;
};

const handleClick = (promotion: Promotion) => {
	loginGuard({
		success: () => {
			if (promotion?.data?.enabled) {
				dispatchGAEvent({ event: "click_button", location: "home", buttonName: "make_deposit" });
				window?.$store?.gaCash?.deposit?.({
					location: "home",
					step: "view_payments_window",
					activePackage: promotion?.data?.money
				});

				openCash(promotion);
			}
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleWheelClick = () => {
	loginGuard({
		success: () => {
			open("LazyOModalDailyWheel");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleSeasonClick = () => {
	loginGuard({
		success: () => {
			if (season?.isEnabled) {
				open("LazyOModalSeasonTower");
			}
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleInviteClick = () => {
	loginGuard({
		success: () => {
			handleClickCopyLink();
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

watch(homePromotions, async () => {
	await nextTick(() => {
		sliderRef.value?.slider?.update();
	});
});

watch(
	() => !appInit.value?.isGuest,
	() => {
		nextTick(() => {
			sliderRef.value?.slider?.update();
		});
	}
);
</script>
<template>
	<div class="promotions">
		<div class="header">
			<AText class="title text-cannes" variant="tamale" as="h3">
				<span>{{ t("Promotions") }}</span>
				<ASvg name="home/promotion" class="text-caracas icon" />
			</AText>
			<AText variant="toledo" :modifiers="['underline']" class="show-all" @click="handleNavigateToPromotions">{{
				t("Show all")
			}}</AText>
		</div>
		<ASlider ref="sliderRef" :options="sliderOptions">
			<div v-if="appInit?.scratchCardLottery?.isActive" class="keen-slider__slide">
				<MPromotionScratchCards />
			</div>
			<div v-if="appInit?.magicBox?.isActive" class="keen-slider__slide">
				<MPromotionMagixBoxes />
			</div>
			<template v-if="homePromotions?.promotions?.length && homePromotions.promotions.length > 0">
				<div v-for="(card, index) in homePromotions.promotions" :key="index" class="keen-slider__slide">
					<MPromotionWheelOfTreasure v-if="card.type === 'depositStreak' && appInit?.depositStreak?.isActive" />
					<MPromotionLeprechaunSeason
						v-else-if="card.type === 'season'"
						:image="getImgFullPath('/nuxt-img/seasons/leprechaun/promo-bg.png')"
						:finishedAt="season?.finishedAt"
						@click="handleSeasonClick"
					/>
					<MPromotionWheel
						v-else-if="card.type === 'dailyWheel'"
						:image="getImgFullPath('/nuxt-img/wheel-promo/bg.png')"
						:type="card.type"
						:text="t(wheelText)"
						@click="handleWheelClick"
					/>
					<MPromotionInvite
						v-else-if="card.type === 'invite'"
						image="/nuxt-img/promotion/invite-bg.png"
						:coins="card.package?.coins || 0"
						:entries="card.package?.entries || 0"
						@click="handleInviteClick"
					/>
					<MPromotionItem
						v-else
						:type="card.type"
						:enabled="card.data?.enabled"
						:image="getImgFullPath(card.package?.imagePopup || '')"
						:coins="card.package?.coins || 0"
						:entries="card.package?.entries || 0"
						:badge="card.badgeLabel"
						:bestDeal="!!card.data?.promoOfferPreset?.bestDeal"
						:mostPopular="!!card.data?.promoOfferPreset?.mostPopular"
						:specialOffer="false"
						:money="card.data?.money"
						:currencySymbol="currencySymbol"
						:subType="card.data?.promoOfferPreset?.subType"
						@click="handleClick(card)"
					/>
				</div>
			</template>
		</ASlider>
	</div>
</template>

<style scoped lang="scss">
.promotions {
	padding: gutter(3) 0;
	position: relative;

	@include media-breakpoint-down(md) {
		padding: gutter(2.5) 0;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: gutter(0) gutter(2);
	}

	.icon {
		font-size: 32px;
		animation: swingAndScale 3s ease-in-out infinite;

		@include media-breakpoint-down(md) {
			font-size: 26px;
		}
	}

	.show-all {
		color: var(--catar);
		cursor: pointer;

		@include media-breakpoint-down(md) {
			font-size: 14px;
		}

		&:hover {
			text-decoration: none;
		}
	}

	.title {
		display: flex;
		align-items: center;
		gap: gutter(1);

		@include media-breakpoint-down(md) {
			font-size: 24px;
			line-height: 30px;
		}
	}

	&:deep(.keen-slider) {
		display: flex;
		position: relative;
		padding-top: gutter(3);

		&::before {
			content: "";
			display: block;
			position: absolute;
			right: 0;
			top: 24px;
			bottom: 0;
			width: 113px;
			background: var(--gaya);
			z-index: 1;

			@include media-breakpoint-down(md) {
				content: none;
			}
		}

		@include media-breakpoint-down(md) {
			padding-top: gutter(2.5);
		}

		.keen-slider__slide {
			width: calc(292px + 4px);
			padding-right: gutter(0.5);
		}
	}
}

@keyframes swingAndScale {
	0% {
		transform: scale(1);
	}

	25% {
		transform: scale(1.4);
	}

	50% {
		transform: rotate(-10deg);
	}

	75% {
		transform: rotate(10deg);
	}

	100% {
		transform: scale(1);
	}
}
</style>
